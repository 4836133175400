import { Container } from "./styles";
import linkedin from "../../assets/linkedin.svg";
import githubIcon from "../../assets/github.svg";
import discordIcon from "../../assets/discord.svg";
import Tooltip from "@mui/material/Tooltip";

export function Footer() {
  return (
    <Container className="footer">
      <a href="https://kalaydzhiev.com" className="logo">
        <span>www.kalaydzhiev.com</span>
      </a>
      <div></div>
      <div className="social-media">
        <a
          href="https://www.linkedin.com/in/aleksandar-kalaydzhiev-06973920b/"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip
            enterTouchDelay={0}
            title={<h1 style={{ fontSize: "1.8rem" }}>LinkedIn</h1>}
            followCursor
          >
            <img src={linkedin} alt="Linkedin" />
          </Tooltip>
        </a>
        <a
          href="https://github.com/xAleksandar"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip
            enterTouchDelay={0}
            title={<h1 style={{ fontSize: "1.8rem" }}>Github</h1>}
            followCursor
          >
            <img src={githubIcon} alt="Github" />
          </Tooltip>
        </a>
        <Tooltip
          enterTouchDelay={0}
          title={<h1 style={{ fontSize: "1.8rem" }}>aleks9268</h1>}
          followCursor
        >
          <img src={discordIcon} alt="Discord" />
        </Tooltip>
      </div>
    </Container>
  );
}
