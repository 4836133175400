import styled from "styled-components";

export const Container = styled.section`
  margin-top: 13rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  .info {
    color: var(--green) !important;
    font-weight: bold;
    margin-top: 6px;
    display: flex;
    max-width: 400px;

    @media (max-width: 700px) {
      max-width: 186px;
      margin: 0 auto;
      margin-top: 6px;
    }

    .info-icon {
      width: 28px;
      height: 28px;
      margin-right: 4px;
    }
  }

  @media (max-width: 700px) {
    .info-icon-wrapper {
      display: flex;
      align-items: center;
    }

    .info-text {
      font-size: 1.4rem;
    }
  }
}

  .cofounder-title {
    @media (max-width: 700px) {
      max-width: 240px;
    }
  }

  p{
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    font-weight: 500;
  }

  h2{
    display: inline-block;
    margin-bottom: 4px;
    font-size :3rem;
    margin-top: 1.9rem;
    color: var(--green);
  }

  .skillsWrapper {
    display: flex;
    justify-content: center;

    @media (max-width: 700px) {
      margin-top: 4rem;
    }

    @media (min-width: 700px) {
      justify-content: right;
      
    }

    .skillsContainer {
      @media (min-width: 700px) {
        padding: 0px 80px;
      }

      .skills {
        margin-top: 1.6rem;
        display: flex;
        align-items: center;
        gap: 1.8rem;

        .skillImage {
          width: 40px;
          height: 40px;
          transition: filter 0.5s;
          &:hover{
            scale: 1.1;
          }
        }
      }
    }

    .familiarSkills {
      margin-top: 8rem;

      @media (max-width: 700px) {
        margin-top: 2rem;
      }
    }
  }

  .scrollTop {
    max-width: 366px;  
  }

  .slider {
    max-width: 324px;
    margin-top: 8px;

    .skillImage {
      width: 40px;
      margin: 2px;
      height: 40px;
      transition: filter 0.5s;
      &:hover{
        scale: 1.1;
      }
    }
  }

  @media only screen and (max-width: 480px) {
  }

  @media (max-width: 960px){
    display: block;
    text-align: center;
    
    skills{
      justify-content: center;
    }
    .about-image{
      display: flex;
      max-width: 100%;
      img{
        margin-top: 2rem;
        width: 100%;
        filter: grayscale(0);
        transition: filter 0.5s;
        &:hover{
          filter: grayscale(0);
        }
    } 
  }
`;
