import { Container } from "./styles";
import githubIcon from "../../assets/github.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="#3bb0ff"
                stroke="#3bb0ff"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/xAleksandar/LNLottery"
                  target="\_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Lightning Network lottery games</h3>
              <p>
                Developing a set og Lightning Network lottery games using NestJS
                backend and NextJS Frontend. The Backend is integrated with
                LNBits API for handling Payments and withdrawals. MongoDB is
                used for storing user data.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>LNBits</li>
                <li>NestJS</li>
                <li>Lightning Network</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="#3bb0ff"
                stroke="#3bb0ff"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <title>Folder</title>{" "}
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>{" "}
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/xAleksandar/Marketplace"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Ethereum NFT Marketplace</h3>
              <p>
                Created a decentralized NFT marketplace using Ethereum
                blockchain, Solidity, React, and IPFS, enabling users to mint,
                buy, and sell NFTs. Integrated NFT Auctions and More. EthersJS
                used for interaction with the Ethereum blockchain.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Ethereum</li> <li>Smart Contracts</li> <li>IPFS</li>{" "}
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="#3bb0ff"
                stroke="#3bb0ff"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>{" "}
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>{" "}
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/xAleksandar"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="Visit site" />{" "}
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Self Improvement</h3>
              <p>
                Developing myself. I am constantly learning new technologies and
                trying to improve my skills. I am always looking for new
                challenges because I believe that's the only way to grow.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Patience</li>
                <li>Motivation</li>
                <li>Hustle</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}
