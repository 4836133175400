import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import { Messages } from "../../lang/en";
import RouteIcon from "../../assets/external-link-icon.svg";

export function Work() {
  return (
    <Container id="work">
      <div className="workWrapper">
        <div className="title">Workplaces</div>
        <div className="workContainer">
          <div className="work">
            <ScrollAnimation animateIn="fadeInLeft">
              <div className="workContent">
                <div className="workTitle">
                  <h2>Alaric Securities</h2>
                  <div className="routeIconWrapper">
                    <a
                      href="https://alaricsecurities.com/platforms/hammer-lite/"
                      target="_blank"
                      rel="noreferrer"
                      className="routeIcon"
                    >
                      <img src={RouteIcon} alt="Route" />
                    </a>
                  </div>
                </div>
                <h1>June 2023 - Present</h1>
                <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
                  <span className="text">
                    {Messages.commonAlaricSecuritiesFirst()}
                  </span>
                  <span className="text-color">Hammer Lite Trade & Invest</span>
                  <span className="text">
                    {Messages.commonAlaricSecuritiesSecond()}
                  </span>
                </ScrollAnimation>
              </div>
            </ScrollAnimation>
          </div>
          <div className="work">
            <ScrollAnimation animateIn="fadeInRight">
              <div className="workContent">
                <div className="workTitle">
                  <h2>Dripit.io</h2>
                  <div className="routeIconWrapper">
                    <a
                      href="https://dripit.io/"
                      target="_blank"
                      rel="noreferrer"
                      className="routeIcon"
                    >
                      <img src={RouteIcon} alt="Route" />
                    </a>
                  </div>
                </div>
                <h1>May 2024 - Present</h1>
                <ScrollAnimation animateIn="fadeInRight" delay={0.1 * 1000}>
                  <span className="text">{Messages.commonDripit()}</span>
                </ScrollAnimation>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Container>
  );
}
