import { BrowserRouter } from "react-router-dom";
import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import Illustration from "../../assets/aleks2.png";
import { NavHashLink } from "react-router-hash-link";
import linkedin from "../../assets/linkedin.svg";
import githubIcon from "../../assets/github.svg";
import discordIcon from "../../assets/discord.svg";
import Tooltip from "@mui/material/Tooltip";

export function Hero() {
  return (
    <Container id="home">
      <div className="hero-text">
        <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
          <h1>Aleksandar Kalaydzhiev</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <h3>Full Stack Web 2/3 Developer</h3>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <p className="small-resume">4 Years of Experience</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.8 * 1000}>
          <BrowserRouter>
            <div className="buttonWrapper">
              <NavHashLink smooth to="#contact" className="button">
                Contact
              </NavHashLink>
            </div>
          </BrowserRouter>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
          <div className="social-media">
            <a
              href="https://www.linkedin.com/in/aleksandar-kalaydzhiev-06973920b/"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip
                enterTouchDelay={0}
                title={<h1 style={{ fontSize: "1.8rem" }}>LinkedIn</h1>}
                followCursor
              >
                <img src={linkedin} alt="Linkedin" />
              </Tooltip>
            </a>
            <a
              href="https://github.com/xAleksandar"
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip
                enterTouchDelay={0}
                title={<h1 style={{ fontSize: "1.8rem" }}>Github</h1>}
                followCursor
              >
                <img src={githubIcon} alt="GitHub" />
              </Tooltip>
            </a>
            <Tooltip
              enterTouchDelay={0}
              title={<h1 style={{ fontSize: "1.8rem" }}>aleks9268</h1>}
              followCursor
            >
              <img src={discordIcon} alt="Discord" />
            </Tooltip>
          </div>
        </ScrollAnimation>
      </div>
      <div className="hero-image">
        <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
          <img className="profile-image" src={Illustration} alt="Ilustração" />
        </ScrollAnimation>
      </div>
    </Container>
  );
}
