import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import { Form } from "../Form/Form";

export function Contact() {
  return (
    <Container id="contact">
      <header>
        <h2>Contact</h2>
      </header>
      <a href="mailto:aleksandar@kalaydzhiev.com">
        <div className="contacts">
          <div>
            <img src={emailIcon} alt="Email" />
            <a href="mailto:aleksandar@kalaydzhiev.com">
              aleksandar@kalaydzhiev.com
            </a>
          </div>
        </div>
      </a>
      <Form></Form>
    </Container>
  );
}
