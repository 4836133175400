export const Messages = {
  commonWhoIAm: () =>
    `Greetings! My Name is Aleksandar. I'm a Full Stack Web Developer
    with a passion for Blockchain technologies and everything related to
    them. I have 4 years of experience in the field, using popular
    technologies such as React, CSS, NodeJS and others.`,
  commonBlockchainEnthusiast: () =>
    `My journey in the blockchain world started in late 2019 when I
    firstly discovered bitcoin. Since then I have been studying all the
    technical aspects of this new technology. I invested a lot of time
    in learning how to develop smart contracts, decentralized
    applications as well as how to secure them. My Web3 stack includes
    Solidity, Hardhat, Ethers.js and other tools.`,
  commonBlockchainEducation: () =>
    `Part of my blockchain educations includes The Limechain Blockchain
    Development Bootcamp, JohnyTime's Smart Contract Hacking course, as
    well as some youtube channels like Secureum, EatTheBlocks and Smart
    Contracts Programmer.`,
  commonCoFounder: () =>
    `Amazed by the potential of blockchain technologies, I decided to
    share my knowledge with the world. In 2024 we founded the Bitcoin
    Club Bulgaria - a place where we teech businesses and individuals
    about the technology potential and how to use most efficiently &
    securely.`,
  commonAlaricSecuritiesFirst: () =>
    `I am one of the leading developers for a Stock Trading Platform
    with zero fees called `,
  commonAlaricSecuritiesSecond: () =>
    `. I am responsible
    for the development of new features, bug fixes and the overall maintenance.
    I am also responsible for the development and the choice of technologies
    for our mobile applications.`,
  commonDripit: () =>
    `I am the lead developer of Dripit.io Backend - a MusicFI platform that
    aims to connect the imagination of the artists with the powers of the
    blockchain. In dripit i am responsible for the development of the
    microservices and the integration of the blockchain in our backend infrastructure.`,
};
