import { Container } from "./styles";

import cssIcon from "../../assets/css-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import mysqlIcon from "../../assets/mysql-icon.svg";
import reactIcon from "../../assets/react-icon.svg";
import solidityIcon from "../../assets/solidity.svg";
import nestIcon from "../../assets/nestjs.svg";
import mongoIcon from "../../assets/mongo.svg";
import hardhatIcon from "../../assets/hardhat-original.svg";
import bitbucketIcon from "../../assets/bitbucket-original.svg";
import jiraIcon from "../../assets/jira-original.svg";
import seleniumIcon from "../../assets/selenium-original.svg";
import reduxIcon from "../../assets/redux-original.svg";
import rxjsIcon from "../../assets/rxjs-original.svg";
import nginxIcon from "../../assets/nginx-original.svg";
import pythonIcon from "../../assets/python-original.svg";
import PandasIcon from "../../assets/pandas-original.svg";
import ethersIcon from "../../assets/ethers-original.svg";
import infoIcon from "../../assets/icon-info.svg";
import gitIcon from "../../assets/git.svg";
import dockerIcon from "../../assets/docker-original.svg";
import typescriptIcon from "../../assets/typescript-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";
import Tooltip from "@mui/material/Tooltip";
import { Messages } from "../../lang/en";
import Marquee from "react-fast-marquee";

export function About() {
  const renderIcon = (name: string, icon: string) => (
    <Tooltip
      title={<h1 style={{ fontSize: "1.8rem" }}>{name}</h1>}
      followCursor
      enterTouchDelay={0}
    >
      <img className="skillImage" src={icon} alt={name} />
    </Tooltip>
  );

  const renderAnimatedIcon = (name: string, icon: string, delay: number) => (
    <ScrollAnimation animateIn="fadeInUp" delay={delay}>
      {renderIcon(name, icon)}
    </ScrollAnimation>
  );

  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Who I am</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>{Messages.commonWhoIAm()}</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>Blockchain Enhtusiast</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>{Messages.commonBlockchainEnthusiast()}</p>
          <Tooltip
            enterTouchDelay={0}
            title={
              <div style={{ fontSize: "1.8rem" }}>
                {Messages.commonBlockchainEducation()}
              </div>
            }
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#2b2b2b",
                  padding: "20px",
                  fontSize: "1.8rem",
                  borderRadius: "1.2rem",
                  color: "white",
                },
              },
            }}
          >
            <p className="info">
              <div className="info-icon-wrapper">
                <img className="info-icon" src={infoIcon} alt="info" />
              </div>
              <span className="info-text">
                View my Blockchain education sources
              </span>
            </p>
          </Tooltip>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft">
          <h2 className="cofounder-title">Bitcoin Club BG co-founder</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>{Messages.commonCoFounder()}</p>
        </ScrollAnimation>
      </div>
      <div className="skillsWrapper">
        <div className="skillsContainer">
          <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 1000}>
            <h2>Current Daily Techstack:</h2>
          </ScrollAnimation>
          <div className="skills">
            {renderAnimatedIcon("React", reactIcon, 0.1 * 1000)}
            {renderAnimatedIcon("CSS", cssIcon, 0.15 * 1000)}
            {renderAnimatedIcon("Typescript", typescriptIcon, 0.2 * 1000)}
            {renderAnimatedIcon("MySql", mysqlIcon, 0.25 * 1000)}
            {renderAnimatedIcon("Solidity", solidityIcon, 0.3 * 1000)}
            {renderAnimatedIcon("NestJS", nestIcon, 0.35 * 1000)}
          </div>
          <div className="familiarSkills">
            <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 1000}>
              <h2>Other tech I have used:</h2>
              <Marquee className="slider" pauseOnHover speed={24}>
                {renderIcon("Redux", reduxIcon)}
                {renderIcon("NodeJS", nodeIcon)}
                {renderIcon("MongoDB", mongoIcon)}
                {renderIcon("Hardhat", hardhatIcon)}
                {renderIcon("EthersJs", ethersIcon)}
                {renderIcon("Bitbucket", bitbucketIcon)}
                {renderIcon("Jira", jiraIcon)}
                {renderIcon("Selenium", seleniumIcon)}
                {renderIcon("RxJS", rxjsIcon)}
                {renderIcon("Nginx", nginxIcon)}
                {renderIcon("Python", pythonIcon)}
                {renderIcon("Pandas", PandasIcon)}
                {renderIcon("Git", gitIcon)}
                {renderIcon("Docker", dockerIcon)}
              </Marquee>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Container>
  );
}
