import styled from "styled-components";

export const Container = styled.section`
  padding-top: 11%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  background: rgba(0, 0, 0, 0);
  .hero-text {
    & > p {
      font-size: 1.8rem;
    }
    h1 {
      font-size: 7rem;
    }

    h3 {
      color: var(--green);
      margin: 1rem 0;
    }

    p.small-resume {
      margin-bottom: 5rem;

      @media (max-width: 700px) {
        margin-bottom: 3 rem;
      }
    }
  }
  // New added
  .social-media {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 5rem;
    padding-left: 1rem;
    margin-left: 14px;

    @media (max-width: 700px) {
      width: 100%;
      justify-content: center;
      margin-left: 0;
      padding-top: 3rem;
    }

    img,
    span {
      font-size: 3rem;
      width: 3.5rem;
    }
  }

  .profile-image {
    margin-right: 40px;
    border-radius: 15%;
  }

  .buttonWrapper {
    @media (max-width: 700px) {
      display: flex;
      justify-content: center;
    }
  }

  .button {
    padding: 1.4rem 6rem;
    @media (min-width: 700px) {
      margin-top: 5rem;
    }
  }

  .hero-image {
    img {
      max-width: 500px;
    }
  }

  @media (max-width: 960px) {
    display: block;
    margin-top: 15%;
    .hero-text {
      h1 {
        font-size: 5rem;
      }
    }

    .hero-image {
      display: none;
    }
  }

  @media (max-width: 600px) {
    margin-top: 35%;
    h1,
    h3,
    p {
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    margin-top: 45%;
  }
`;
