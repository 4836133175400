import styled from "styled-components";

export const Container = styled.section`
  margin-top: 12rem;
  width: 100%;

  .text {
    color: #FFFF;
  }

  .title {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
    font-weight: bold;
  }

  .workContainer {
    margin: 0 auto;
    gap: 2rem;

    @media (min-width: 700px) {
      display: flex;
      width: 50%;
      justify-content: space-between;
    }

    .work {
      @media (min-width: 700px) {
        max-width: 50%;
        min-width: 282px;
      }

      .workContent {
        background-color: #2b2b2b;
        padding: 20px;
        border-radius: 1.2rem;
        &:hover {
          @media (min-width: 700px) {
            background-color: var(--pink);
            scale: 1.04;
          }
        }

        @media (max-width: 700px) {
            margin-top: 2rem;
        }
      }

      .workTitle {
        display: flex;
      }

      .routeIconWrapper {
        display: flex;
        align-items: center;
        margin-left: 1.2rem;

        .routeIcon {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }

        .routeIcon:hover {
          transform: scale(1.1);
        }
      }
    }

  span {
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    font-weight: 500;
  }

  h1 {
    font-size: 1.8rem;
    margin: 4px 0px;
    color: gray;
  }

  h2 {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 3rem;
    margin-top: 1.9rem;
    color: var(--green);
  }

  .text-color {
    color: var(--green);
    font-weight: bold;
  }
`;
